import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'src/authConfig';
import * as serviceWorker from 'src/serviceWorker';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import Login from './Login';
import { CurrentEmployeeProvider } from './contexts/CurrentEmployeeContext';


const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AuthenticatedTemplate>
      <React.StrictMode>
        <CurrentEmployeeProvider>
          <HelmetProvider>
            <SidebarProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SidebarProvider>
          </HelmetProvider>
        </CurrentEmployeeProvider>
      </React.StrictMode>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </MsalProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
