import React from 'react'
import { msalEvent } from './hooks/msalEvent'

const Login = () => {
    msalEvent();
    return (
        <>
        </>
    )
}

export default Login