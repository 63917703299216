export const getDomain = (isLocalhost = false) => {
    return "https://readmegen-api.azurewebsites.net"
}


export const currentSystem = () => {
    if (window.location.href.includes("agentx.readmegen.com")) {
        return "https://agentx.readmegen.com/"
    } else {
        return "http://localhost:3000/"
    }
}