import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react'
import { acquireToken, requestUserData } from 'src/authConfig';
import { getDomain } from 'src/settings';

export enum API_REQUEST {
    SUCCESS = "success",
    LOADING = "loading",
    ERROR = "error"
}

export const useApi = (endpoint: string, delay = 100, version = "v1", isLocalhost = false) => {

    const { instance, accounts } = useMsal();

    const [data, setData] = useState<any | null>(null);
    const [request, setRequest] = useState<API_REQUEST>(null);
    const [timerReference, setTimerReference] = useState(undefined);
    const [dataRefresh, setDataRefresh] = useState<boolean>(false);
    const fetchData = async () => {
        if (!endpoint.includes("null") && !endpoint.includes("undefined")) {
            setRequest(API_REQUEST.LOADING)
            try {
                const domain = getDomain(isLocalhost);
                const accessTokenRequest = {
                    ...requestUserData,
                    account: accounts[0],
                };

                instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        axios.get(`${domain}/${version}/${endpoint}`, {
                            headers: {
                                Authorization: 'Bearer ' + accessTokenResponse.accessToken
                            }
                        }).then((response) => {
                            setData(response.data)
                            setRequest(API_REQUEST.SUCCESS)
                        })
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequest);
                        }
                        console.log(error);
                    });
            } catch (error) {
                console.error(error.message)
                setRequest(API_REQUEST.ERROR)
            }
        }
    }

    useEffect(() => {
        setTimerReference(setTimeout(() => {
            fetchData()
        }, delay));
        return () => {
            clearTimeout(timerReference);
        }
    }, [endpoint, dataRefresh])

    return {
        data,
        request,
        setDataRefresh
    }
}
