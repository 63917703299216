import React from 'react'
import { useMsal } from "@azure/msal-react";
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Button } from '@mui/material';


function signOutClickHandler(instance: IPublicClientApplication, accounts: AccountInfo[]) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0].homeAccountId)
    };
    instance.logoutRedirect(logoutRequest);
}

const Logout = () => {
    const { instance, accounts } = useMsal();

    return (
        <Button color="primary" fullWidth onClick={() => signOutClickHandler(instance, accounts)}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign oout
        </Button>
    )
}

export default Logout