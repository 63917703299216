import React, { useEffect } from 'react'
import { EventType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

export const msalEvent = () => {

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            (async () => {
                await instance.handleRedirectPromise();
                const accounts = instance.getAllAccounts();
                if (accounts.length === 0) {
                    await instance.loginRedirect()
                }
            })()
        }
        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
            instance.setActiveAccount(instance.getAllAccounts()[0])
        }

        instance.addEventCallback((event: any) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                instance.setActiveAccount(account);
            }
        })

    }, [instance, isAuthenticated])
}